
#header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 20px;

	.logo {
		img {
			width: 200px;
		}
	}

	.nav {
		margin: 20px;

		.nav-item {
			font-size: 18px;
			color: #333;
			text-decoration: none;
			margin-right: 20px;

			.mobile & {
				margin-right: 10px;
			}

			&:hover {
				color: #000;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.mobile & {
			display: none;
			flex-direction: column;
			width: 100%;
			margin: 0;

			& .nav-item {
				margin: 3px 0;
				padding: 3px 0;

				&.active {
					border: 0px;
					border-left: 4px solid #CCC;
					padding-left: 5px;
				}
			}
		}

		.mobile &.nav-active {
			display: flex;
		}
	}

	.nav-toggle {
		display: none;
	}

	.nav-toggle-label {
		display: none;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 48px;
		right: 50px;
		cursor: pointer;
	}

	.mobile & {
		.nav-toggle {
			display: none;
		}

		.nav-toggle-label {
			display: block;
		}
	}

	.nav-toggle:checked+.nav-toggle-label+.nav {
		display: flex;
	}
}

#musaic-logo {
	img {
		width: 300px;

		.mobile & {
			margin: 10px auto;
		}
	}
}

#prompt-section {
	width: 500px;
	position: absolute;
	top: 100px;
	left: 100px;

	.mobile & {
		width: calc(100% - 20px)
	}

	.section-content {
		position: relative;
		padding-right: 50px;
		
		svg.fa-play, svg.fa-arrows-rotate {
			position: absolute;
			right: 5px;
			top: 10px;
			margin: 3px;
			margin-right: 0px;

			&:hover {
				color: #000;
			}
		}
	}
	
}

#seed-section {
	position: absolute;
	top: 100px;
	left: 810px;
	width: 300px;

	.mobile & {
		width: 100%;
	}

	.section-title {
		opacity: 0;
	}

	.seeds {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.seed {
			opacity: 0;
			margin-right: 10px;
			margin-bottom: 10px;

			position: relative;
			background: #FFF;
			border-radius: 5px;
			overflow: hidden;
			width: 60px;
			height: 60px;
	
			/* em shadow */
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.18);
	
			img {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}

		&.less {
			.seed {
				width: 70px;
				height: 70px;
			}
		}
	}
}

#midi-section {
	position: absolute;
	width: 400px;

	.midi {
		opacity: 0;
	}
}

#seed-to-render-arrow {
	.mobile & {
		z-index: -10;

		.arrow-title-holder {
			top: -20%;
			left: 10%;
		}
	}
}

#seed-to-rights-arrow {
	.mobile & {
		z-index: -10;
	}
}

#audio-section {
	position: absolute;
	top: 600px;
	left: 500px;

	.player {
		opacity: 0;
	}

	.section-content {
		display: flex;
		color: #4968f0;
		padding: 20px 15px;

		.fa-play, .fa-pause {
			margin-top: 6px;
			margin-right: 5px;
		}
	}
}

#rights-section {
	position: absolute;
	top: 600px;
	left: 920px;

	.mobile & {
		max-width: calc(100% - 20px);
	}

	.rights {
		opacity: 0;
	}

	.section-content {
		display: flex;
		color: #333;

		.rights-section {
			margin: 0px 10px;
		}

		li {
			margin-left: 15px;
		}
	}
}

.example {
	background: #FFFFE0;
	padding: 10px;
	border-radius: 5px;
	color: #000;

	b {
		color: #333;
	}

	/* em shadow */
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.18);
}

.demo-page {
	position: relative;
	padding-bottom: 50px;
	margin-bottom: 50px;
	max-width: 100%;
	height: 1900px;
	overflow-x: hidden;

	.arrow {
		position: absolute;
		pointer-events: none;

		.arrow-title-holder {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;

			display: grid;
			place-items: center;

			.arrow-title {
				text-align: center;
				padding-bottom: 30px;
				opacity: 0;
				color: #333;
			}

		}
	}

	.section {

		.section-title {
			font-size: 22px;
			font-weight: semi-bold;
			color: #333;
			padding-left: 10px;
			padding-bottom: 3px;
		}

		.section-content {
			background: #FFF;
			padding: 10px;
			border-radius: 5px;

			/* em shadow */
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.18);
		}
	}

	.example {
		width: 800px;
		margin-top: 10px;
		margin-left: 100px;

		.mobile & {
			width: calc(100% - 20px);
			margin: 10px auto;
		}
	}
}

.nav {
	margin-bottom: 20px;
	border-bottom: 1px solid #eee;

	.nav-item {
		cursor: pointer;
		display: inline-block;
		margin-right: 20px;
		padding: 10px 0;
		font-size: 18px;
		color: #999;
		text-decoration: none;

		&.active {
			border-bottom: 2px solid #CCC;
			color: #333;
			font-weight: bold;
		}

		&:hover {
			color: #000;
			border-bottom: 2px solid #000;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}