

.holder {
	display: grid;
	place-items: center;
	height: 100vh;
	margin: 0;
}

h1 {
	text-align: center;
}