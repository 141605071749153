
.derivative {
	width: 880px;
	max-width: 880px;
	margin: 0px 20% 20px;

	.example {
		margin: 10px 5%;
	}

	#derivative-album {
		display: flex;
		flex-wrap: wrap;

		.section {
			width: 320px;
		}

		.album_info {
			margin-top: 14px;
			div {
				margin: 5px 0px;
			}
		}

		.derivative-album-track.section {
			width: 520px;
			margin: 10px;

			.section-content {
				padding: 8px 15px;
			}
			
			.mobile & {
				width: 350px;
			}
		}

	}

	&.mobile {
		width: auto;
		margin: 0px 5% 20px;

		#derivative-album {
			display: block;
				
			.section {
				width: 250px;
				margin: 0 auto;
			}

			.derivative-album-tracks {
				margin-top: 10px;
			}
	
			.derivative-album-track.section {
				margin-top: 10px;
				width: 370px;
			}	
		}
	}

	.derivative-album-track {

		.section-title {
			font-size: 12px;
		}

		.section-content {
			display: flex;
			color: #4968f0;
			padding: 20px 15px;

			.fa-play,
			.fa-pause {
				margin-top: 6px;
				margin-right: 5px;
			}
		}
	}

	#derivative-controls {
		.section-content {
			cursor: pointer;
			width: 350px;
			margin: 20 auto;
			font-size: 26px;
			color: #FFF;
			background-color: #EBA947;
			text-align: center;
		}
	}

	.generation {
		width: 850px;
		margin-top: 15px;

		.section-title {
			font-family: 'Courier New', Courier, monospace;
			font-size: 10px;
		}

		.section-content {
			display: flex;
			position: relative;
			padding-right: 50px;
			color: #4968f0;
			padding: 20px 15px;
		
			.fa-play,
			.fa-pause {
				margin-top: 6px;
				margin-right: 5px;
			}

			.fa-download {
				margin-left: 10px;
			}

			.fa-arrows-rotate {
				position: absolute;
				right: 15px;
				top: 13px;
				margin: 3px;
				margin-right: 0px;

				&:hover {
					color: #000;
				}
			}
		}
	}

	&.mobile .generation {
		width: 370px;
		margin: 10 auto;

		.section-title {
			white-space: nowrap;
			overflow: hidden;
		}

		.download-link {
			display: none;
		}
	}

	.rights {
		width: 600px;
		margin: 25px auto;

		.section-content {
			display: flex;
			color: #333;

			.rights-section {
				width: 40%;
				margin: 0px 10px;
			}

			li {
				margin-left: 15px;
			}
		}

		.mobile & {
			max-width: calc(100% - 20px);
		}

	}
}

