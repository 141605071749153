
.delphos {
	padding: 20px 20%;

	#generator {
		width: 500px;

		.section-content {
			position: relative;
			padding-right: 50px;

			svg.fa-play, svg.fa-arrows-rotate {
				position: absolute;
				right: 5px;
				top: 10px;
				margin: 3px;
				margin-right: 0px;

				&:hover {
					color: #000;
				}
			}
		}
	}

	.generation {
		width: 800px;
		margin-top: 15px;

		.section-title {
			font-family: 'Courier New', Courier, monospace;
			font-size: 10px;
		}

		.section-content {
			display: flex;
			position: relative;
			padding-right: 50px;
			color: #4968f0;
			padding: 20px 15px;
		
			.fa-play,
			.fa-pause {
				margin-top: 6px;
				margin-right: 5px;
			}

			.fa-download {
				margin-left: 10px;
			}

			.fa-arrows-rotate {
				position: absolute;
				right: 15px;
				top: 13px;
				margin: 3px;
				margin-right: 0px;

				&:hover {
					color: #000;
				}
			}
		}
	}
}

