
#auth-page {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	place-items: center;
	height: 100vh;
	margin: 0;

	.login-holder {
		flex-basis: 100%;
		flex-grow: 0;
		justify-content: center;

		#musaic-logo {
			img {
				margin: 0 auto 20;
			}
		}

		#login {
			width: 320px;
			margin: 0 auto;
			align-items: center;

			input, button {
				display: block;
				margin-top: 5px;
				padding: 12px 20px;
				font-size: 1rem;
				border-width: 2px;
				border-style: solid;
				border-color: #333;
				border-radius: 10px;
				text-align: center;
				outline: transparent;
				width: 100%;

				font-family: 'Noto Sans', sans-serif;
				
				&.submit {
					margin: 20px auto 0;
					width: 60%;
				}
			}

			.error {
				color: red;
				font-size: 0.8rem;
				margin-top: 5px;
			}
		}
	}

}