
.adaptive {
	width: 880px;
	max-width: 880px;
	margin: 0px 20% 20px;

	&.mobile {
		width: auto;
		margin: 0px 3% 20px;

		.generation {
			margin: 0px;

			.section-content {
				flex-wrap: nowrap;
				margin: 0px 3%;
			}

			&.with_image {
				.section-content {
					flex-wrap: wrap;
				}
			}
		}

	}

	.example {
		margin: 10px 5%;
	}

	.generation {
		width: 850px;
		margin: 15px;

		.section-content {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			padding-right: 50px;
			color: #4968f0;
			padding: 20px 15px;

			.clean-player {
				display: flex;
			}
		
			.fa-play,
			.fa-pause {
				margin-top: 6px;
				margin-right: 5px;
			}

			.fa-download {
				margin-left: 10px;
			}

			.fa-arrows-rotate {
				position: absolute;
				right: 15px;
				top: 13px;
				margin: 3px;
				margin-right: 0px;

				&:hover {
					color: #000;
				}
			}

			img {
				margin-top: 10px;
				border: 1px solid #999;
			}
		}
	}

	&.mobile .generation {
		width: 370px;
		margin: 10 auto;

		.section-title {
			white-space: nowrap;
			overflow: hidden;
		}

		.download-link {
			display: none;
		}
	}

	.rights {
		width: 600px;
		margin: 25px auto;

		.section-content {
			display: flex;
			color: #333;
	
			.rights-section {
				width: 40%;
				margin: 0px 10px;
			}
	
			li {
				margin-left: 15px;
			}
		}

		.mobile & {
			max-width: calc(100% - 20px);
		}

	}
}

